import { Controller } from "stimulus";

export default class extends Controller {
  /* Displays the changed-filters-button if any input or select field is chaned
    <div data-controller="filter-change">
      <input type="text"/>
      <select name="category">
        <option value="option1">Option 1</option>
        <option value="option2">Option 2</option>
      </select>

      <!-- Buttons that toggle visibility based on filter changes -->
      <button data-filter-change-unhide-button>Changes Made</button>
      <button data-filter-change-hide-button>No Changes</button>
    </div>
  */

  connect() {
    console.log("filter controller connected");
    this.initializeFilters();
  }

  initializeFilters() {
    // Select all input and select elements in the form
    const inputs = this.element.querySelectorAll("input, select");

    // Add event listeners to all form inputs and selects
    inputs.forEach((input) => {
      input.addEventListener('change', this.filterChanged.bind(this));
    });
  }

  filterChanged(event) {
    console.log("Filter changed:", event.target);

    document.querySelector(
      '[data-filter-change-hide-button]'
    ).classList.add("d-none");
    document.querySelector(
      '[data-filter-change-unhide-button]'
    ).classList.remove("d-none");
  }
}